import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override, Menu } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"ovoid"} />
		<Helmet>
			<title>
				O-VOID: Console Edition
			</title>
			<meta name={"description"} content={"Videogame Unichrome: A 1-Bit Unicorn Adventure on Steam, Playstation, Xbox and Nintendo Switch."} />
			<meta property={"og:title"} content={"O-VOID: Console Edition"} />
			<meta property={"og:description"} content={"In this super minimal action runner, you’ll be jumping over obstacles with the tap of a button. But this is not a regular platformer! The entire level can change direction or flip upside down to throw you off. Are you skilled enough to survive every challenge?"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/4k.jpg?v=2024-05-31T00:30:38.347Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/120.png?v=2024-05-31T00:29:00.368Z"} type={"image/x-icon"} />
		</Helmet>
		<Components.Menuu />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/4k_back.png?v=2024-05-31T00:43:03.750Z) center/cover" md-background="url(https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/New-Art-16x9-4k-cover.png?v=2022-09-09T00:10:26.928Z) center/cover no-repeat scroll">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="460px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h4"
					margin="0"
					font="--base"
					letter-spacing="1px"
					text-transform="uppercase"
				>
					BY{" "}
					<Link href="https://gearheadgames.com/" target="_blank" color="#000000">
						Gearhead Games ApS{"\n\n"}
					</Link>
				</Text>
				<Text as="h1" margin="0 0 16px 0" font="--headline2" lg-font="--headline2">
					O-VOID: Console Edition
				</Text>
				<Text as="p" margin="16px 0" font="--base" max-width="400px">
					In this super minimal action runner, you’ll be jumping over obstacles with the tap of a button. But this is not a regular platformer! The entire level can change direction or flip upside down to throw you off. Are you skilled enough to survive every challenge?
				</Text>
				<Link
					margin="3px 3px 3px 3px"
					color="#F7FBFF"
					text-decoration-line="initial"
					display="inline-block"
					hover-background="--color-darkL1"
					href="https://store.playstation.com/concept/10010579"
					target="_blank"
					background="--color-primary"
					padding="12px 20px 12px 20px"
					border-radius="2px"
					text-align="center"
					width="100%"
				>
					Playstation
				</Link>
				<Link
					margin="3px 3px 3px 3px"
					color="#F7FBFF"
					text-decoration-line="initial"
					display="inline-block"
					hover-background="--color-darkL1"
					href="https://www.xbox.com/games/store/o-void-console-edition/9n6mfqrdb0hw"
					target="_blank"
					background="--color-primary"
					padding="12px 20px 12px 20px"
					border-radius="2px"
					text-align="center"
					width="100%"
				>
					Xbox One
				</Link>
				<Link
					margin="3px 3px 3px 3px"
					color="#F7FBFF"
					text-decoration-line="initial"
					display="inline-block"
					hover-background="--color-darkL1"
					href="https://www.nintendo.com/us/store/products/o-void-console-edition-switch/"
					target="_blank"
					background="--color-primary"
					padding="12px 20px 12px 20px"
					border-radius="2px"
					text-align="center"
					width="100%"
				>
					Nintendo Switch (America)
				</Link>
				<Link
					text-decoration-line="initial"
					display="inline-block"
					hover-background="--color-darkL1"
					background="--color-primary"
					padding="12px 20px 12px 20px"
					margin="3px 3px 3px 3px"
					color="#F7FBFF"
					target="_blank"
					border-radius="2px"
					text-align="center"
					href="https://www.nintendo.com/en-gb/Games/Nintendo-Switch-download-software/O-VOID-Console-Edition-2574728.html"
					width="100%"
				>
					Nintendo Switch (Europe)
				</Link>
				<Link
					text-decoration-line="initial"
					text-align="center"
					href="https://store-jp.nintendo.com/list/software/70010000079252.html"
					background="--color-primary"
					padding="12px 20px 12px 20px"
					color="#F7FBFF"
					display="inline-block"
					hover-background="--color-darkL1"
					target="_blank"
					margin="3px 3px 3px 3px"
					border-radius="2px"
					width="100%"
				>
					Nintendo Switch (Japan)
				</Link>
			</Box>
		</Section>
		<Section text-align="center" padding="10px 0 10px 0" sm-padding="40px 0">
			<Box display="flex" margin="10px 0 10px 0" justify-content="space-around" sm-flex-direction="column" />
		</Section>
		<Section text-align="center" padding="10px 0 10px 0" sm-padding="40px 0">
			<Box display="flex" margin="10px 0 10px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px" width="100%">
					<Image
						width="100%"
						max-width="100%"
						src="https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/2.jpg?v=2024-05-31T00:36:07.398Z"
						srcSet="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/2.jpg?v=2024-05-31T00%3A36%3A07.398Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/2.jpg?v=2024-05-31T00%3A36%3A07.398Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/2.jpg?v=2024-05-31T00%3A36%3A07.398Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/2.jpg?v=2024-05-31T00%3A36%3A07.398Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/2.jpg?v=2024-05-31T00%3A36%3A07.398Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/2.jpg?v=2024-05-31T00%3A36%3A07.398Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/2.jpg?v=2024-05-31T00%3A36%3A07.398Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box width="100%" padding="10px">
					<Image
						src="https://uploads.quarkly.io/5e9db7bb052a30001fc650d8/images/3.jpg?v=2024-05-31T00:36:20.443Z"
						width="100%"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/3.jpg?v=2024-05-31T00%3A36%3A20.443Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/3.jpg?v=2024-05-31T00%3A36%3A20.443Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/3.jpg?v=2024-05-31T00%3A36%3A20.443Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/3.jpg?v=2024-05-31T00%3A36%3A20.443Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/3.jpg?v=2024-05-31T00%3A36%3A20.443Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/3.jpg?v=2024-05-31T00%3A36%3A20.443Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/5e9db7bb052a30001fc650d8/images/3.jpg?v=2024-05-31T00%3A36%3A20.443Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.QuarklycommunityKitYouTube url="https://www.youtube.com/watch?v=pc4nApa1SJo" />
		<Components.Htmlform />
		<Section background-color="--dark" text-align="center" padding="32px 0" sm-background="#0f1923">
			<Menu
				font-weight="700"
				margin="-6px 0 16px"
				md-flex-direction="column"
				md-align-items="center"
				display="flex"
				justify-content="center"
				font="--lead"
			>
				<Override slot="link-active" color="--primary" />
				<Override slot="item" padding="6px 0px" />
				<Override slot="link" text-decoration="none" color="--light" padding="6px 12px" />
			</Menu>
			<Link
				href="mailto:hello@company.com"
				text-decoration-line="none"
				variant="--base"
				color="--grey"
				hover-color="--primary"
			/>
		</Section>
		<Link href="#" color="#000000" />
	</Theme>;
});